import { useForm } from "@inertiajs/vue3";
import { toast } from "vue3-toastify";
import { defineEmits } from "vue";

export const useEmailForm = (redirectUrl) => {
    const emailForm = useForm({
        email: null,
    });

    const emailSubmit = () => {
        emailForm.post(route("subscribe"), {
            onSuccess: (page) => {
                emailForm.reset();
                toast.success(page.props.flash.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
                // Redirect to external URL on success
                if (redirectUrl) {
                    setTimeout(() => {
                        window.location.href = redirectUrl;
                    }, 2000);
                }
            },
            onError: (errors) => {
                toast.error(errors.message, {
                    position: toast.POSITION.BOTTOM_RIGHT,
                });
            },
            preserveScroll: true,
        });
    };

    return { emailForm, emailSubmit };
};
